* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
}

Link {
  text-decoration: none;
}

.global-header {
  width: 100%;
  background-color: #7eb2dd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-bar {
  display: flex;
  gap: 10px;
  padding-right: 5vw;
}

.logo {
  display: block;
  height: 100px;
}

.nav-btns {
  width: 200px;
  height: 75px;
  background-color: #7eb2dd;
  border: none;
  font-size: 40px;
  color: yellow;
  border-radius: 10px;
}

.nav-btns:hover {
  background-color: yellow;
  color: #7eb2dd;
  cursor: pointer;
}
.logo-footer {
  height: 100px;
}

.global-footer {
  background-color: #7eb2dd;
  display: flex;
  align-items: center;
  padding-left: 10vw;
  padding-right: 10vw;
  justify-content: space-evenly;
}

.copyright {
  font-size: 15px;
}

.footer-links {
  text-decoration: none;
  color: yellow;
}

.footer-links:hover {
  color: darkgoldenrod;
}

input {
  display: block;
}

.btn-submit {
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  height: 50px;
  width: 200px;
  background-color: #1fd694;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 24px;
  border-radius: 15px;
}

.btn-submit:hover {
  cursor: pointer;
  background-color: #17a873;
}

.create-account-page {
  padding-top: 15vh;
}

.login-page {
  padding-top: 15vh;
}

.register-form {
  border-top: 5px solid black;
  padding-top: 3vh;
}

.login-form {
  border-top: 5px solid black;
  padding-top: 3vh;
}

.form-container {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  /* background-color: red; */
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.input-single {
  border: none;
  height: 5vh;
  width: 15vw;
  border-radius: 10px;
  background-color: #d9d9d9;
}

.input-multi {
  display: block;
  resize: none;
  width: 15vw;
}

.field-label {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
}
.field-label-dark {
  font-size: 32px;
  font-weight: 600;
  color: #050505;
}

.heading-bold {
  font-size: 36px;
  color: white;
  margin-bottom: 20px;
}

input[type="email"] {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  width: 25rem;
  font-weight: 600;
}
input[type="text"] {
  padding-left: 15px;
  padding-right: 15px;
  width: 25rem;
  font-size: 16px;
  font-weight: 600;
}
textarea[type="text"] {
  padding: 15px;
  font-size: 16px;
  font-weight: 575;
  height: 15rem;
  width: 25rem;
}

input[type="password"] {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  font-weight: 600;
  width: 25rem;
}

.item-cards-container {
  margin-top: 5vh;
  margin-bottom: 5vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 40vw;
  height: 65vh;
  overflow-y: scroll;
  mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 10px,
    black calc(100% - 30px),
    transparent 100%
  );
  gap: 20px;
}

.item-cards-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: none;
}

.item-cards-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.item-card {
  width: 100%;
  height: 80px;
  background-color: rgb(233, 252, 152);
  border-radius: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 8%;
}

.card-title {
  margin-left: 1%;
}

.card-description {
  margin-left: 1%;
}

.item-card:hover {
  cursor: pointer;
}

.item-card-img {
  width: 5rem;
  border-radius: 5px;
}

.cart-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 75px;
}

.total-costs {
  width: 25vw;
  height: 65vh;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  justify-content: center;
  align-items: center;
}

.store-card-img {
  max-height: 12rem;
  max-width: 12rem;
  border-radius: 5px;
  /* height: 200px; */
}

.store-card-title {
}

.store-card {
  background-color: rgb(228, 245, 133);
  border-radius: 5px;
  /* flex-basis: 30%; */
  height: 400px;
  width: 300px;
  padding: 1rem;
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.storePage-container {
  /* overflow-y: scroll; */
  display: flex;
  max-width: 80vw;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.inventory-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}

/*item-details-page*/

.item-details-page {
  display: flex;
  padding-top: 3rem;
  gap: 3rem;
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.item-description-img {
  height: 35rem;
  border-radius: 5px;
  display: block;
}
.item-info-container {
  display: flex;
  flex-direction: column;
  min-width: 18rem;
  gap: 1.5rem;
}

.cycle-img-btns-container {
  padding-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 2rem;
}

.cycle-img-btn {
  display: block;
  width: 5rem;
  height: 4rem;
  background-color: yellow;
  color: rgb(0, 183, 255);
  border-radius: 5px;
  border: none;
  font-size: 2.5rem;
}
.cycle-img-btn:hover {
  background-color: rgb(167, 167, 4);
  color: white;
  cursor: pointer;
}

.item-details-heading {
  text-align: center;
}

.item-details-price {
  text-align: center;
  color: rgb(7, 216, 0);
}

.item-details-description {
  font-size: 1.2rem;
  font-weight: 500;
}

.add-item-cart-btn {
  margin-top: 10rem;
  background-color: yellow;
  border: none;
  font-size: 2rem;
  font-weight: 500;
  color: rgb(0, 183, 255);
  border-radius: 5px;
  height: 5rem;
}

.add-item-cart-btn:hover {
  background-color: rgb(167, 167, 4);
  color: white;
  cursor: pointer;
}

/*end*/

.cart-imgs {
  max-width: 200px;
  max-height: 200px;
}

.img-testing {
  max-width: 75px;
  max-height: 75px;
}

.account-details-container {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  /* background-color: red; */
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #7eb2dd;
}

/*Contact us page CSS*/
.contact-us-container {
}

.contact-us-heading {
  text-align: center;
  padding-top: 1.5rem;
}

.contact-us-img-desc-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 60vw;
  gap: 1.5rem;
  padding: 1.5rem;
}

.contact-us-img {
  width: 40vw;
  border-radius: 5%;
}

.contact-us-text-container {
  text-align: center;
}

.contact-us-text {
  font-size: 20px;
}

/*Privacy policy page */

.privacy-policy-container {
}

.privacy-policy-heading {
  text-align: center;
  padding-top: 1.5rem;
}

.privacy-policy-img-desc-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 60vw;
  gap: 1.5rem;
  padding: 1.5rem;
}

.privacy-policy-img {
  width: 40vw;
  border-radius: 5%;
}

.privacy-policy-text-container {
  text-align: center;
}

.privacy-policy-text {
  font-size: 20px;
}

/*About Page*/

.about-page-container {
}

.about-page-heading {
  text-align: center;
  padding-top: 1.5rem;
}

.about-page-img-desc-container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 60vw;
  gap: 1.5rem;
  padding: 1.5rem;
}

.about-page-img {
  width: 40vw;
  border-radius: 5%;
}

.about-page-text-container {
  text-align: center;
}

.about-page-text {
  font-size: 20px;
}

/*Create Listing Page*/
.create-listing-container {
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.list-item-heading {
  text-align: center;
  font-size: 3rem;
}

.inputs-container {
  /* background-color: #17a873; */
  /* height: 1000px; */
  width: 1100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.photo-picker {
  width: 45%;
  height: 38rem;
}

.info-inputs {
  width: 45%;
  height: 42rem;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.file-btn-label {
  width: 100%;
  height: 100%;
  display: inline-block;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: rgb(218, 218, 218);
  border-radius: 5px;
  text-align: center;
}

.file-btn-label:hover {
  cursor: pointer;
  background-color: rgb(170, 170, 170);
}

.add-listing-input {
  display: block;
  background-color: rgb(218, 218, 218);

  height: 4rem;
  border-radius: 5px;
  border: none;
}

.add-listing-input:hover {
  background-color: rgb(170, 170, 170);
}

.add-listing-input-desc {
  display: inline-block;
  background-color: rgb(218, 218, 218);

  resize: none;
  border-radius: 5px;
  border: none;
  width: 75%;
}

.add-listing-input-desc:hover {
  background-color: rgb(170, 170, 170);
}

.create-listing-btn {
  display: block;
  width: 25rem;
  height: 6rem;
  background-color: #17a873;
  border: none;
  color: white;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.2rem;
  border-radius: 5px;
}
.create-listing-btn:hover {
  background-color: #1fe29b;

  cursor: pointer;
}

/*Edit listing*/

.edit-listing-container {
  /* background-color: red; */
  margin-left: auto;
  margin-right: auto;
  gap: 3rem;
  width: 1100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.edit-listing-img-container {
  width: 45%;
}

.edit-listing-img {
  width: 100%;
  border-radius: 5px;
}

.edit-listing-info {
  /* background-color: blue; */
  width: 30rem;
  height: 42rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
}

.edit-listing-input {
  display: block;
  background-color: rgb(218, 218, 218);
  height: 4rem;
  border-radius: 5px;
  border: none;
}

.edit-listing-input:hover {
  background-color: rgb(170, 170, 170);
}

.edit-listing-description {
  display: inline-block;
  background-color: rgb(218, 218, 218);

  resize: none;
  border-radius: 5px;
  border: none;
  width: 75%;
}

.edit-listing-description:hover {
  background-color: rgb(170, 170, 170);
}

.edit-file-btn {
  width: 20%;
  height: 5rem;
  display: inline-block;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: rgb(218, 218, 218);
  border-radius: 5px;
  text-align: center;
}
.edit-file-btn:hover {
  background-color: rgb(170, 170, 170);
  cursor: pointer;
}

.submit-edit-btn {
  height: 4rem;
  width: 12rem;
  background-color: #1fd694;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 2rem;
}

.submit-edit-btn:hover {
  background-color: #17a873;
  cursor: pointer;
}

/*My Cart Container*/

.my-cart-container {
  width: 100rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  display: flex;
  /* flex-direction: column; */
  gap: 2rem;
}

.cart-items {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 5rem;
}

.cart-price-container {
}

.cart-card {
  display: flex;
  height: 12rem;
  background-color: #daff84;
  border-radius: 5px;
  padding: 2rem;
}

.cart-card-img {
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.cart-card-title {
  font-size: 2rem;
}

.cart-item-button {
  align-items: center;
  background-color: rgb(255, 189, 127);
  border: none;
  border-radius: 5px;
  width: 3rem;
  font-size: 2rem;
}

.cart-item-button:hover {
  background-color: rgb(166, 125, 87);
  cursor: pointer;
}

.cart-item-quantity {
  font-size: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.delete-btn-cart {
  height: 6rem;
  width: 6rem;
  margin-left: auto;
  background-color: #f08080;
  font-size: 3rem;
  margin-top: auto;
  margin-bottom: auto;
  color: white;
  border: none;
}

.delete-btn-cart:hover {
  background-color: #ad5b5b;
  cursor: pointer;
}

.cart-card-section {
  width: 10rem;
  margin-left: 2rem;
  display: flex;
  justify-content: baseline;
  align-items: center;
}
.my-cart-heading {
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}
.cart-card-section-btns {
  width: 10rem;
  margin-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-card-section-title-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 15rem;
  padding: 1rem;
}

.cart-card-section-img {
  width: 10rem;
  margin-left: 2rem;
  display: flex;
  justify-content: baseline;
  align-items: center;
}

.grand-total {
}

.place-order {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 3rem;
  background-color: #1fd694;
  border: none;
  color: white;
  margin-top: 3rem;
  font-size: 2rem;
}

.place-order:hover {
  background-color: #17a873;
  cursor: pointer;
}
